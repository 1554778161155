import React, { useContext, useEffect, useState } from 'react';
import { useRecordContext, useVersion } from 'react-admin';
import { apiClient } from '../../http';

const ParticipantAccountContext = React.createContext(null);

export const useParticipantAccount = () => useContext(ParticipantAccountContext);

export const ParticipantAccountProvider = props => {
  const participant = useRecordContext();
  const [ accounts, setAccount ] = useState();
  const version = useVersion();

  useEffect(() => {
    apiClient(`/participants/${participant.id}/identities`, { method: 'GET' })
      .then(res => res.data)
      .then(identities => {
        fetchAccount(identities[0])
      })

    function fetchAccount(identity) {
      return apiClient(`/accounts/${identity.accountId}`, { method: 'GET' })
        .then(res => res.data)
        .then(account => {
          setAccount(account)
        })
    }
  }, [version, participant.id])

  return <ParticipantAccountContext.Provider value={accounts} {...props} />
}
