import React, { Fragment } from 'react'
import { FunctionField, GET_LIST, ReferenceField, TextField, useLocale, useQuery, useRecordContext, useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';
import { GavelOutlined } from '@material-ui/icons';

import { translateApiProperty } from '@hisports/common';

import { TextLocaleField } from '../../../common/fields/TextLocaleField';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../../common/Authorize';
import ListCard from '../../../common/cards/ListCard';
import { OfficeField } from '../OfficeField';

import { AddSanctionButton, SanctionDatagrid } from '../../sanctions/SanctionModal';
import { AddSanctionMemberButton, SanctionMemberDatagrid } from '../../sanctions/SanctionMemberModal';
import { AddSanctionOffenseButton } from '../../sanctions/SanctionOffenseModal';
import { SanctionOffenseGrid } from '../../sanctions/SanctionOffenseGrid';
import { formatCodes } from '../../sanctions/RuleOptionInput';
import { InfractionField } from '../../infractions/InfractionField';

const getRuleCode = rule => rule?.code ? `${rule.code} ` : '';

const useSanctionMembers = sanctionId => useQuery({
  type: GET_LIST,
  resource: 'sanctionMembers',
  payload: {
    filter: {
      sanctionId,
      _include: [
        {
          relation: 'option',
          scope: {
            include: [
              { relation: 'infractions' },
              { relation: 'rule' },
            ],
          },
        },
        {
          relation: 'infraction',
          scope: {
            include: [
              { relation: 'rule' },
            ],
          },
        },
      ] }
  },
}, {
  enabled: sanctionId != null,
})

const RuleOptionField = ({ variant = 'body2', ...props }) => {
  return <ReferenceField basePath="/ruleOptions" resource="ruleOptions" reference="ruleOptions" {...props} link={false}>
    <TextLocaleField source="name" variant={variant} />
  </ReferenceField>
}

const RuleSectionField = ({ variant = 'body2', ...props }) => {
  return <ReferenceField basePath="/ruleSections" resource="ruleSections" reference="ruleSections" {...props} link={false}>
    <FunctionField source="name" render={rule => <>
      {getRuleCode(rule)}
      <TextLocaleField source="name" variant={variant} />
    </>} />
  </ReferenceField>
}

const NameField = props => {
  const locale = useLocale();
  return <FunctionField {...props} render={record => translateApiProperty(record, 'name', locale)} />;
}

const ParentSanctionCode = props =>
  <ReferenceField basePath="/sanctions" resource="sanctions" reference="sanctions" {...props} link={false}>
    <TextField source="code" />
  </ReferenceField>

const ParentSanctionOffice = props =>
  <ReferenceField basePath="/sanctions" resource="sanctions" reference="sanctions" {...props} link={false}>
    <OfficeField source="officeId" />
  </ReferenceField>

const SanctionMemberGrid = ({ rowOptions, ...props }) => {
  const sanction = useRecordContext();
  const locale = useLocale();
  const { data: sanctionMembers } = useSanctionMembers(sanction?.id);

  if (!sanctionMembers) return null;

  const renderRule = record => {
    const sanctionMember = sanctionMembers.find(({ id }) => id === record.id)
    const rule = sanctionMember?.option?.rule || sanctionMember?.infraction?.rule
    if (!rule) return null;

    return `${getRuleCode(rule)} ${translateApiProperty(rule, 'name', locale)}`;
  }
  const renderCodes = record => {
    const sanctionMember = sanctionMembers.find(({ id }) => id === record.id)
    const infractions = sanctionMember?.option?.infractions || [sanctionMember?.infraction].filter(Boolean);
    if (!infractions) return null;

    return formatCodes(infractions);
  }

  return <SanctionMemberDatagrid rowOptions={{ sanctionMembers, ...rowOptions }} {...props} >
    <FunctionField source="rule" label="resources.sanctionMembers.labels.rule" render={renderRule} />
    <RuleOptionField source="optionId" />
    <InfractionField source="infractionId" />
    <FunctionField source="code" label="resources.sanctionMembers.labels.code" render={renderCodes} />
  </SanctionMemberDatagrid>;
}

const SanctionGrid = ({ office, ...props }) => {
  const translate = useTranslate()
  const hasParentOffice = !!office?.parentId

  const renderPositionGroups = ({ positionGroups = [] }) =>
    positionGroups?.map(positionGroup => translate(`resources.sanctions.values.positionGroup.${positionGroup}`, { _: positionGroup })).join(', ');

  return <SanctionDatagrid {...props}>
    <TextField source="code" />
    <NameField source="name" />
    <RuleSectionField source="sectionId" />
    <TextField source="accumulationCount" />
    <FunctionField source="positionGroups" render={renderPositionGroups} />
    {hasParentOffice && <ParentSanctionCode source="parentId" label={translate('resources.sanctions.labels.parent_code')} />}
    {hasParentOffice && <ParentSanctionOffice source="parentId" label={translate('resources.sanctions.labels.parent_office')} />}
    <TextField source="order" />
  </SanctionDatagrid>;
}

const SanctionOffenseList = ({ office, ...props }) => {
  const sanction = useRecordContext(props);

  const canAddSanctionOffense = isAuthorized(office, 'sanctionOffenses', 'create');

  return <ListCard
    reference="sanctionOffenses"
    resource="sanctionOffenses"
    target="sanctionId"
    sort={{ field: ['offense'], order: ['ASC'] }}
    perPage={25}
    rowsPerPageOptions={[]}
    addPosition="footer"
    addButton={canAddSanctionOffense && <AddSanctionOffenseButton initialValues={{ sanctionId: sanction?.id, durationType: 'Definite' }} />}
    component={Fragment}
  >
    <SanctionOffenseGrid />
  </ListCard>
}

const SanctionMemberList = ({ office, ...props }) => {
  const sanction = useRecordContext(props);

  const canAddSanctionMember = isAuthorized(office, 'sanctionMembers', 'create');

  return <ListCard
    reference="sanctionMembers"
    resource="sanctionMembers"
    target="sanctionId"
    sort={{ field: ['optionId', 'infractionId'], order: ['ASC', 'ASC'] }}
    perPage={25}
    rowsPerPageOptions={[]}
    addPosition="footer"
    addButton={canAddSanctionMember && <AddSanctionMemberButton initialValues={{ sanctionId: sanction?.id }} office={office} />}
    component={Fragment}
  >
    <SanctionMemberGrid rowOptions={{ office }} office={office} />
  </ListCard>
}

const SanctionDetails = ({ office, ...props }) => {
  const translate = useTranslate()
  return <>
    <Typography variant="subtitle2">{translate('resources.sanctionMembers.labels.card.title')}</Typography>
    <Typography variant="body2" color="textSecondary">{translate('resources.sanctionMembers.labels.card.subtitle')}</Typography>
    <SanctionMemberList office={office} {...props} />

    <Typography variant="subtitle2">{translate('resources.sanctionOffenses.labels.card.title')}</Typography>
    <Typography variant="body2" color="textSecondary">{translate('resources.sanctionOffenses.labels.card.subtitle')}</Typography>
    <SanctionOffenseList office={office} {...props} />
  </>
}

export default props => {
  const office = useRecordContext(props);
  const seasonId = useSeason();

  const canAddSanction = isAuthorized(office, 'sanctions', 'create');

  return <ListCard
    title="resources.sanctions.labels.card.title"
    subtitle="resources.sanctions.labels.card.subtitle"
    collapsable
    icon={<GavelOutlined />}
    reference="sanctions"
    target="officeId"
    filter={{ seasonId }}
    sort={{ field: ['section.order, order, code'], order: ['ASC', 'ASC', 'ASC'] }}
    addPosition="footer"
    addButton={canAddSanction && <AddSanctionButton initialValues={{ officeId: office?.id, seasonId }} office={office} />}
    rowsPerPageOptions={[]}
    perPage={99999}
  >
    <SanctionGrid expand={<SanctionDetails office={office} />} rowOptions={{ office }} office={office} />
  </ListCard>
}

