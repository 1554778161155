import React from 'react';
import { Datagrid, TextField } from 'react-admin';

import FunctionField from '../../common/fields/FunctionField'
import { SMAChip } from '../../common/chips/SMAChip';

export const ParticipantGrid = props =>
  <Datagrid size="medium" {...props}>
    <FunctionField source="lastName" label="resources.participants.fields.name" render={participant => participant.fullName} />
    <TextField source="HCRId" />
    <FunctionField source="accounts.email" label="resources.participants.fields.accountEmail" render={participant => <ParticipantAccountEmail participant={participant} />} />
  </Datagrid>

const ParticipantAccountEmail = ({ participant }) => {
  return participant.accounts && participant.accounts[0]?.externalId
    ? <>
      <span>{participant.accounts[0]?.email}</span>
      <SMAChip />
    </>
    : <span>{participant.accounts && participant.accounts[0]?.email}</span>
}
