import React, { useMemo } from 'react';
import { useTranslate, useRecordContext, RecordContextProvider, ResourceContextProvider } from 'react-admin';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardActions, Box, Grid, Typography, makeStyles, styled } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import moment from 'moment-timezone';
import Helmet from 'react-helmet';

import { FF_SSO } from '@hisports/common/featureFlags';

import { useAccount, useParticipant, useFilteredScopes, useFlag } from '../../http';
import { isAuthorized } from '../../common/Authorize';
import { useSeason } from '../../common/inputs/SeasonSelector';
import { TabbedShowLayout, Tab } from '../../common/ra/TabbedShowLayout';
import AvailabilityCard from '../../resources/availabilities/AvailabilityCard';
import AvailabilitySettings from '../../resources/officials/AvailabilitySettings';

import AccountCard, { LogoutButton } from './cards/AccountCard';
import ContactsCard from './cards/ContactsCard';
import AddressesCard from './cards/AddressesCard';
import ProfileCard from './cards/ProfileCard';
import AnnouncementsCard from './cards/AnnouncementsCard';
import EventsCard from './cards/EventsCard';
import QualificationsCard from './cards/QualificationsCard';
import SsoLinkAccount from './reminders/SsoLinkAccount';
import ChangePasswordModal from './dialogs/ChangePasswordModal';
import ContactsReminder from './reminders/ContactsReminder';
import SuspensionsCard from './cards/SuspensionsCard';
import GamePenaltiesCard from './cards/GamePenaltiesCard';
import TransactionsCard from './cards/TransactionsCard';
import ClaimsCard from './cards/ClaimsCard';
import { SMAChip } from '../../common/chips/SMAChip';

const Page = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: theme.spacing(150),
  marginLeft: 'auto',
  marginRight: 'auto',
}))

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '25%',
    flexShrink: 0,
  },
  description: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  ssoChip: {
    color: theme.palette.lightBlue[500],
    borderColor: theme.palette.lightBlue[500],
    backgroundColor: theme.palette.lightBlue[50],
    marginLeft: theme.spacing(1)
  },
  ssoIcon: {
    color: theme.palette.lightBlue[500]
  }
}))

const Section = ({ title, description, defaultExpanded, children, actions }) => {
  const classes = useStyles();
  return <Accordion square defaultExpanded={defaultExpanded}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.title}>{title}</Typography>
      {description && <Typography className={classes.description}>{description}</Typography>}
    </AccordionSummary>
    {children && <AccordionDetails>
      {children}
    </AccordionDetails>}
    {actions}
  </Accordion>
}

const SummaryTab = props => {
  const account = useRecordContext(props);
  const translate = useTranslate();
  const isEnabled = useFlag();
  const scopes = useFilteredScopes(['System', 'Tenant', 'Office', 'Schedule', 'Participant']);
  const classes = useStyles()

  const today = moment().startOf('day').toISOString()
  const future = moment().startOf('day').add(1, 'year').toISOString()
  const showAnnouncements = isAuthorized(scopes, 'announcements', 'list');

  if (!account?.participantId) return null;

  return <Tab {...props}>
    <Helmet title="Profile" />

    <ContactsReminder />
    {isEnabled(FF_SSO) && !account?.externalId && <SsoLinkAccount />}
    <Card>
      <Section
        title={translate('resources.contacts.name', 1)}
        description={translate('resources.contacts.messages.description')}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ContactsCard />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AddressesCard />
          </Grid>
        </Grid>
      </Section>

      <Section
        title={translate('resources.profiles.name', 1)}
        description={translate('resources.profiles.messages.description')}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ProfileCard />
          </Grid>
        </Grid>
      </Section>

      <Section
        title={translate('resources.accounts.name', 1)}
        description={<>
          {translate('ra.auth.youre_logged_in_as', { user: account?.username })}
          {account?.externalId && <SMAChip />}
        </>}
        actions={
          <CardActions>
            <ChangePasswordModal />
            <LogoutButton />
          </CardActions>
        } />
    </Card>

    <br />
    <Grid container spacing={2}>
      {showAnnouncements && <Grid item xs={12}>
        <AnnouncementsCard />
      </Grid>}
      <Grid item xs={12}>
        <EventsCard
          title={translate('resources.events.labels.upcoming_schedule')}
          filter={{ startTime: today, endTime: future }}
          perPage={10}
        />
      </Grid>
    </Grid>
  </Tab>
}

const OfficiatingTab = props => {
  const account = useRecordContext(props);
  const scopes = useFilteredScopes(['Participant']);

  if (!account?.participantId) return null;
  if (!isAuthorized(scopes, 'profile', 'official')) return null;

  const canViewTransactions = isAuthorized(account, 'officialTransactions', 'list');
  const canViewClaims = isAuthorized(scopes, 'officialClaims', 'show')

  return <Tab {...props}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Helmet title="Availability" />
        <ResourceContextProvider value="participants">
          <AvailabilityCard settings={AvailabilitySettings} editable />
        </ResourceContextProvider>
      </Grid>
      <Grid item xs={12}>
        <QualificationsCard />
      </Grid>
      {canViewTransactions && <Grid item xs={12}>
        <TransactionsCard collapsable />
      </Grid>}
      {canViewClaims && <Grid item xs={12}>
        <ClaimsCard collapsable />
      </Grid>}
    </Grid>
  </Tab>
}

const LeagueTab = props => {
  const account = useRecordContext(props);
  const scopes = useFilteredScopes(['Team', 'Participant']);

  if (!account?.participantId) return null;
  if (!isAuthorized(scopes, 'profile', 'league')) return null;

  const showSuspensions = isAuthorized(scopes, 'suspensions', 'show')
  const showGamePenalties = isAuthorized(scopes, 'gamepenalties', 'show')

  return <Tab {...props}>
    <Grid container spacing={2}>
      {showGamePenalties && <Grid item xs={12}>
        <GamePenaltiesCard />
      </Grid>}
      {showSuspensions && <Grid item xs={12}>
        <SuspensionsCard />
      </Grid>}
    </Grid>
  </Tab>
}

const GamesTab = props => {
  const account = useRecordContext(props);
  const scopes = useFilteredScopes(['Team', 'Participant']);
  const seasonId = useSeason();

  if (!account?.participantId) return null;
  if (!isAuthorized(scopes, 'profile', 'games')) return null;

  return <Tab {...props}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <EventsCard
          title="resources.events.labels.card.title"
          filter={{ seasonId, pastEvents: true }}
        />
      </Grid>
    </Grid>
  </Tab>
}

export default props => {
  const participantId = useParticipant();
  const account = useAccount();
  const record = useMemo(() => ({
    ...account,
    participantId,
    id: participantId,
  }), [ account, participantId ])

  if (!account) return null;
  if (!participantId) return <Page>
    <Box mt={2}>
      <RecordContextProvider value={record}>
        <ResourceContextProvider value="accounts">
          <AccountCard />
        </ResourceContextProvider>
      </RecordContextProvider>
    </Box>
  </Page>

  return <Page>
    <RecordContextProvider value={record}>
      <ResourceContextProvider value="accounts">
        <TabbedShowLayout>
          <SummaryTab label="resources.profiles.labels.tabs.summary" />
          <OfficiatingTab label="resources.profiles.labels.tabs.officiating" path="officiating" />
          <LeagueTab label="resources.profiles.labels.tabs.league" path="league" />
          <GamesTab label="resources.profiles.labels.tabs.schedule" path="games" />
        </TabbedShowLayout>
      </ResourceContextProvider>
    </RecordContextProvider>
  </Page>
}
